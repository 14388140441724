.bg-blur {
  background: transparent !important;
  backdrop-filter: blur(10px) !important;
}

/* CONTACT PAGE CSS  START*/

/* Banner Styling */
.slider-area {
  position: relative;
}

.carousel-container .carousel {
  cursor: grab !important;
}

.carousel-container .carousel:active {
  cursor: grabbing !important;
}

.banner-content-overlay {
  position: absolute;
  top: 61%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: start;
}

.banner-content-overlay .title,
.banner-content-overlay .subtitle {
  color: white;
}

.banner-title {
  font-size: 36px;
  font-weight: 700;
}

.subtitle {
  font-size: 15px;
  color: rgba(99, 96, 96, 1);
  /* padding-left: 45px; */
}
.card-counter .counter-num{
  font-weight: 500 !important
}

.contact-form-container {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

/* Contact Form Styling */
.contact-address {
  background-color: #cdddef;
  padding: 40px;
  border: #fff 10px solid;
}

.contact-address:hover {
  background-color: #bbd8fa;
}

.contact-form {
  background-color: rgba(238, 238, 238, 1);
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
}

.contact-icon {
  color: rgba(18, 83, 155, 1);
  font-size: 18px;
}

.container-checkbox1 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

.container-checkbox1 p {
  font-size: 13px;
}

.container-checkbox1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  /* border-radius: 4px; */
  transition: background-color 0.3s;
}

.container-checkbox1 input:checked~.checkmark {
  background-color: #1e4c96;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox1 input:checked~.checkmark:after {
  display: block;
}

.container-checkbox1 .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.btn-primary {
  width: 100%;
  padding: 12px;
  background-color: #1e4c96;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.phone-contact,
.email-contact,
.address-contact {
  color: black !important;
}

/* .phone-contact:hover,
.email-contact:hover,
.address-contact:hover {
  color: #0056b3 !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .banner-content-overlay {
    font-size: 80%;
  }

  .form-title {
    font-size: 20px;
  }

  .btn-primary {
    font-size: 16px;
  }

  .banner-content-overlay {
    position: absolute;
    top: 55%;
    left: 39%;
    transform: translate(-50%, -50%);
  }
}

/* CONTACT PAGE CSS  END*/

/* PRODUCT CSS START */

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  display: none !important;
}

.image-gallery-left-nav {
  display: none !important;
}

.image-gallery-right-nav {
  display: none !important;
}

.product-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.product-box {
  border: 1px solid rgb(172, 171, 171);
  border-radius: 10px;
  transition: border-color 0.3s, color 0.3s;
  cursor: pointer;
}

.product-box:hover {
  border: 1px solid rgba(18, 83, 155, 1);
}

.product-box h6 {
  color: rgb(172, 171, 171);
  font-weight: bold;
  transition: color 0.3s;
}

.product-box:hover h6 {
  color: rgba(18, 83, 155, 1);
}

.product-box p {
  color: rgb(172, 171, 171) !important;
  font-weight: 300 !important;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1px;
}

.border-active {
  border: 1px solid rgba(18, 83, 155, 1);
}

.border-active h6 {
  color: rgba(18, 83, 155, 1);
}

.img-dark1 {
  filter: brightness(30%);
}

.img-dark2 {
  filter: brightness(50%);
}

.product-overlay-text {
  position: relative;
  top: 25%;
  left: 8%;
  width: 35%;
}

.product-overlay-text h2 {
  color: white;
  font-weight: bold;
  font-size: 2rem;
}

.product-overlay-text h5 {
  color: white;
  font-weight: bold;
  font-size: 1.25rem;
}

.product-overlay-text p {
  color: white;
  text-align: justify;
  font-weight: 300 !important;
  font-size: 0.8rem;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #12529b !important;
  border: none !important;
  background-color: transparent !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: #12529b !important;
  border: none !important;
}

.nav-tabs {
  border-bottom: none !important;
}

.nav-link {
  color: black !important;
}

.nav-tabs .nav-link {
  border: none !important;
}

/* PRODUCT CSS END */

/* MILESTON CSS START */
.milestone-section {
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.milestone-dis {
  font-weight: 400;
  line-height: 25px;
  text-align: justify;
  letter-spacing: normal;
}

.timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 2px;
  background: #12539b;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.milestone {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 50%;
}

.milestone.left {
  left: 0;
}

.milestone.right {
  left: 50%;
}

.milestone::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: #ffffff;
  border: 2px solid #12539b;
  border-radius: 16px;
  z-index: 1;
}

.milestone.right::after {
  left: -8px;
}

.milestone::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  top: calc(50% - 1px);
  right: 8px;
  background: #12539b;
  z-index: 1;
}

.milestone.right::before {
  left: 8px;
}

.milestone .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 8px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #12539b;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.milestone.left .date {
  right: -75px;
}

.milestone.right .date {
  left: -75px;
}

.milestone .icon {
  position: absolute;
  display: inline-block;
  width: 64px;
  height: 64px;
  padding: 16px 0;
  top: calc(43% - 20px);
  background: #fff;
  border: 1px solid #12539b;
  border-radius: 40px;
  text-align: center;
  font-size: 14px;
  color: #12539b;
  z-index: 1;
}

.milestone.left .icon {
  right: 56px;
}

.milestone.right .icon {
  left: 56px;
}

.milestone .content {
  padding: 30px 100px 30px 30px;
  background: #f6f6f6;
  position: relative;
  border-radius: 0 500px 500px 0;
}

.milestone.right .content {
  padding: 30px 30px 30px 100px;
  border-radius: 500px 0 0 500px;
}

.milestone .content h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: normal;
  color: #12539b;
}

.milestone .content p {
  margin: 0;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.milestone.left p {
  text-align: right;
}

.milestone.right p {
  text-align: left;
}

.slider-tab p {
  font-size: medium;
}

.slider-tab p:hover {
  color: #12529b;
}

.slider-tab p.active {
  color: #12529b;
}

/* Mobile adjustments */
@media (max-width: 767.98px) {
  .timeline::after {
    left: 10px;
  }

  .milestone {
    width: 100%;
    padding-left: 30px;
    padding-right: 10px;
  }

  .milestone.right {
    left: 0%;
  }

  .milestone.left::after,
  .milestone.right::after {
    left: 0;
  }

  .milestone.left::before,
  .milestone.right::before {
    left: 0;
    border-color: transparent #12539b transparent transparent;
  }

  .milestone.left .icon,
  .milestone.right .icon {
    left: 30px;
  }

  .milestone.left .content,
  .milestone.right .content {
    /* padding: 20px 30px; */
    padding: 20px 20px 20px 55px;
    border-radius: 20px;
  }

  .milestone .content p {
    font-size: 13px;
    line-height: 20px;
    text-align: left !important;
  }

  .milestone .icon {
    width: 50px;
    height: 50px;
    font-size: 12px;
  }

  .show-more {
    color: #0b73bc;
    margin-left: 4px;
    cursor: pointer;
  }
}

/* MILESTON CSS END */

/* ABOUT CSS START */
.about-section {
  padding: 3rem 1.5rem;
}

.cg {
  margin-top: 4rem;
}

.border-cg {
  border-right: 1px solid gray;
}

.custum-col {
  width: 20%;
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tons {
  font-weight: normal;
  font-size: 14px;
  color: gray;
}

.about-title {
  margin-bottom: 1rem;
}

.about-title h1 {
  font-size: 2rem;
  font-weight: bold;
}

.about-title span {
  color: #12539b;
}

.about-dis {
  line-height: 28px;
  font-size: 13px;
  text-align: justify;
  padding: 2rem;
}

.about-border {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #9b8b8b;
  padding-right: 1rem;
}

.about-card {
  border: 1px solid transparent;
  background-color: #ecf5ff;
  /* border: 1px solid #12539b; */
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
  text-align: center;
  transition: border-color ease-in-out 0.6s;
}

.about-card:hover {
  border-color: #12539b;
  cursor: pointer;
}

.about-card img {
  max-width: 100%;
  height: 70px;
  margin-bottom: 0.5rem;
}

.about-card-title {
  font-size: 1rem;
  color: #12539b;
  margin: 0;
}

.about-card.border-active {
  border-color: #12539b;
  border-radius: 0.5rem;
}

.text-content {
  padding: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .custum-col {
    width: 33%;
    height: 70px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .about-border {
    border-right: none;
    border-bottom: 1px solid #9b8b8b;
    padding-bottom: 1rem;
  }

  .about-title h1 {
    font-size: 1.5rem;
  }

  .about-card {
    padding: 0.5rem;
  }

  .about-card-title {
    font-size: 0.875rem;
  }
}

.image-container {
  text-align: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.text-content {
  padding: 0rem;
}

.about-dis {
  padding: 0rem;
}

.product-overlay-text {
  position: relative;
  top: 25%;
  left: 8%;
  width: 50%;
}

.product-overlay-text h2 {
  color: white;
  font-weight: bold;
  font-size: 1.8rem;
}

.product-overlay-text h5 {
  color: white;
  font-weight: bold;
  font-size: 1.15rem;
}

.product-overlay-text p {
  color: white;
  text-align: justify;
  font-weight: 300 !important;
  font-size: 0.7rem;
}

.tab-item {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  transition: color 0.3s ease;
  outline: none;
}

.tab-item p:hover {
  color: #1e4c96;
}

.active-tab p {
  color: #1e4c96;
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .image-container img {
    max-width: 100%;
    height: auto;
  }
}

.vision-mission {
  background-color: #f6f6f6;
  /* padding: 45px; */
  border: 1px solid #12539b;
  height: 330px;
}

/* ABOUT CSS END */

@media (max-width: 425px) {
  .custum-col {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: start;
  }

  .border-cg {
    border-right: none;
    border-bottom: 1px solid rgb(212, 212, 212);
  }

  /* .banner-content-overlay {
    position: absolute;
    top: 70%;
    left: 40%;
    transform: translate(-50%, -50%);
  } */

  .banner-content-overlay h2 {
    font-size: 26px;
  }

  .banner-content-overlay p {
    font-size: 11px;
    line-height: 17px;
  }

  .product-overlay-text {
    position: relative;
    top: 15%;
    left: 8%;
    width: 75%;
  }

  .product-overlay-text h2 {
    color: white;
    font-weight: bold;
    font-size: 1.6rem;
  }

  .product-overlay-text h5 {
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
  }

  .product-overlay-text p {
    color: white;
    text-align: justify;
    font-weight: 300 !important;
    font-size: 0.7rem;
  }
}

.management-team {
  flex: 1 1 calc(20% - 10px);
  position: relative;
  width: 100%;
}

.management-team img {
  width: 100%;
  transition: filter 0.3s ease-in-out;
}

.management-team:hover .mgmt-details {
  height: 100%;
}

.management-team:hover .mgmt-details strong {
  transform: translateY(0);
}

.management-team:hover .mgmt-details p {
  opacity: 1;
  transform: translateY(-50%);
}

.mgmt-details {
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
  text-align: center;
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: 0;
  height: 0;
  transition: height 1s cubic-bezier(0.37, 0, 0.54, 1);
  font-size: medium;
  border-top-left-radius: 19%;
  border-top-right-radius: 19%;
}

.mgmt-details h5 {
  display: block;
  height: 40%;
  text-transform: uppercase;
  line-height: 48px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  transform: translateY(80%);
  transition: all 1s cubic-bezier(0.37, 0, 0.54, 1);
}

.mgmt-details p {
  opacity: 0;
  padding: 0 10px 5px 10px;
  line-height: 21px;
  height: 40%;
  color: #fff;
  transform: translateY(100%);
  transition: all 1s cubic-bezier(0.37, 0, 0.54, 1);
  font-size: 15px;
}