/* WeBelieve.css */
/* .we-believe-container {
  padding: 30px 15px;
} */

.cursor-pointer {
  cursor: pointer;
}

.about-eagle-container {
  padding: 65px 0;
}

.about-container {
  background: #ecf5ff;
}

.vision-mission-title {
  font-size: 18px;
}

.vision-mission-img {
  width: 46px;
}

.curve-img img {
  border-top-left-radius: 95px;
}

.about-para {
  font-size: 13px;
  text-align: justify;
}

.section-title .title {
  font-size: 28px;
  /* font-weight: 700; */
  color: #333;
}

.section-title .sub-title {
  font-size: 20px;
  font-weight: 500;
  color: #777;
}

.we-believe-left {
  border: 1px solid #cdc8c8;
  padding: 5px 14px;
  border-radius: 28px;
  text-align: center;
}

.we-believe-left p {
  font-weight: 600;
  font-size: 16px;
  color: black;
}

.webelieve-card {
  border: none !important;
  /* background-color: gainsboro; */
  border-radius: 8px;
  text-align: start;
  transition: transform 0.3s ease-in-out;
  padding: 20px 0px;
}

.we-1 {
  background: #d9d2f3 !important;
}

.we-2 {
  background: #ddd9d6 !important;
}

.we-3 {
  background: #efeb64 !important;
}

.we-4 {
  background: #c5d1c3 !important;
}

.webelieve-card:hover {
  transform: translateY(-10px);
}

.webelieve-section {
  padding: 25px;
}

.webelieve-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.webelieve-description {
  font-size: 15px;
  color: #363636;
  font-weight: 400;
  margin-bottom: 10px;
}

.webelieve-section img {
  height: 50px;
  width: 50px;
}

.webelieve-section small {
  color: black;
}

.webelieve-section small:hover {
  color: #0b73bc;
}


.nav-btn {
  background: #12529b;
  font-size: 12px;
}

.nav-btn:hover {
  background: #063061;
  transition: all ease-in-out 0.6s;
}

.footer-bg {
  background: #031a33 !important;
}

.mobile-menu-bg {
  background: #031a33 !important;

}

.right-border-class {
  border-right: 1px solid #233644;
}

.wrap-border {
  border: 2px solid #233644;
  border-radius: 10px;
  padding: 10px;
  margin: 2px !important;
}

.card-counter img {
  width: 40px;
  height: 40px;
}

.card-counter .counter-unit {
  color: var(--tg-primary-color);
}

.business-text-overlay {
  bottom: 0;
  padding: 20px;
  text-align: center;
}

.business-text-overlay p {
  text-align: justify;
}

.rotate-icon {
  transform: rotate(-45deg);
}

.underline-offset {
  text-underline-offset: 5px;
}

.img-brightness {
  position: relative;
  display: inline-block;
}

.img-brightness::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.9) 100%);
  pointer-events: none;
}

.img-brightness figure {
  margin-bottom: 0px !important;
}

.letter-spacing-1 {
  letter-spacing: 0.1rem;
}

.letter-spacing-2 {
  letter-spacing: 0.2rem;
}

.letter-spacing-3 {
  letter-spacing: 0.3rem;
}

.text-truncate-three {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer-contact {
  display: flex;
  align-items: center;
}

.footer-contact .footer-logo {
  width: 150px;
}

.contact-info {
  background: #e0efff;
}

.contact-title .title {
  font-size: 40px;
  font-weight: 500;
}

.contact-now-circle {
  width: 100px;
  height: 100px;
  font-size: 13px;
  background: #1e4c96;
  color: white;
  text-decoration: none;
}

.contact-now-circle:hover {
  background-color: #0a2757;
  transition: all ease-in-out 0.6s;
}

.companies-card {
  border: 1px solid #adadad;
  background: #f0f0f0;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}

.companies-card p {
  font-size: 13px;
  transition: color 0.3s ease;
}

.companies-card:hover {
  background: #031a33 !important;
  border: 1px solid #031a33 !important;
  transition: all ease-in-out 0.6s;
}

.companies-card:hover p {
  color: #fff !important;
  transition: all ease-in-out 0.6s;
}

.companies-card img {
  width: 65px;
}


.company-box {
  position: relative;
}

.company-text {
  position: relative;
  z-index: 2;
  color: white;
  top: 25%;
}

.company-text h5,
.company-text .company-dec {
  color: white !important;
}

.company-dec {
  font-size: 13px;
  font-weight: normal !important;
}


.revenue-box {
  width: 220px;
  position: absolute;
  top: 24%;
  border: solid 1px #12539B;
  border-radius: 10px;
  /* left: 8%; */
  background: white;
}

.revenue-box p {
  color: #12539B;
}

.management-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}


.management-dis {
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 31px;
  text-align: justify;
  letter-spacing: normal;
}

.team-name {
  padding-top: 16px;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  line-height: 26px;
}

.management-job {
  font-size: 14px;
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 6px;
}

.management-add {
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
}

.management-team-sec {
  padding: 3rem 0rem;
  padding-bottom: 0px !important;
}

.management-team {
  flex: 1 1 calc(20% - 10px);
  position: relative;
  width: 100%;
}

.management-team img {
  width: 100%;
  transition: filter 0.3s ease-in-out;
}

.management-team:hover .mgmt-details {
  height: 100%;
}

.management-team:hover .mgmt-details strong {
  transform: translateY(0);
}

.management-team:hover .mgmt-details p {
  opacity: 1;
  transform: translateY(-50%);
}

.mgmt-details {
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
  text-align: center;
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: 0;
  height: 0;
  /* transition: height 0.8s ; */
  transition: height 1s cubic-bezier(0.37, 0, 0.54, 1);
  font-size: medium;
  border-top-left-radius: 19%;
  border-top-right-radius: 19%;
}

.mgmt-details h5 {
  display: block;
  height: 40%;
  text-transform: uppercase;
  line-height: 48px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  transform: translateY(80%);
  /* transition: transform 0.8s , opacity 0.8s ; */
  transition: all 1s cubic-bezier(0.37, 0, 0.54, 1)
}

.mgmt-details p {
  opacity: 0;
  padding: 0 10px 5px 10px;
  line-height: 25px;
  height: 40%;
  color: #fff;
  transform: translateY(100%);
  /* transition: transform 0.8s, opacity 0.8s; */
  transition: all 1s cubic-bezier(0.37, 0, 0.54, 1);
  font-size: 18px;
}


.contact-item {
  display: flex;
  gap: 15px;
  color: black;
}

.contact-item .ci-icon {
  font-size: 22px;
}

.contact-item .ci-text {
  margin: auto 0px;
  color: var(--tg-paragraph-color);
}

.list-wrap li a {
  list-style: none;
  color: var(--tg-silver);
  font-size: 12px;
}

.list-wrap li a .s-icon {
  font-size: 17px;
}

.nav-accordion-title {
  font-size: 14px;
  font-weight: 300 !important;
}

.accordion-button {
  color: #fff !important;
  background-color: #031a33 !important;
  padding: 1rem 1rem !important;
  font-size: 15px !important;
  font-weight: 300 !important;
}

.accordion-collapse.collapse.show {
  background-color: #031a33 !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
  background-color: #031a33 !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-header {
  border-bottom: 1px solid #0f456b !important;
}

.accordion-body {
  padding: 0.5rem 1.25rem !important;
}



.banner-brightness {
  filter: brightness(75%);
}

/* .banner-content-overlay {
  position: absolute;
  top: 40%;
  left: 9%;
  text-align: start;
} */



.rotate-icon {
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out;
}

.rotate-icon-2 {
  transform: rotate(180deg);
  transition: all ease-in-out 0.3s;
}

.reset-icon {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}




/* Navbar.css */
.mobile-menu.open {
  transform: translateX(0);
}

.explore-more,
.know-more {
  color: black;
  /* font-weight: 500; */
  font-size: 13px;
}

.explore-more:hover,
.know-more:hover {
  color: #0b73bc;
  transition: all ease-in-out 0.3s;
}

.project-content .read-more {
  color: white;
}

.project-content .read-more:hover {
  color: #0b73bc;
  transition: all ease-in-out 0.3s;
}

.home-projects .project-content1 {
  position: absolute !important;
  bottom: 3% !important;
  text-align: start;
}

.homeProduct {
  padding: 0rem !important;
  cursor: pointer;
}

.homeProductCursor {
  cursor: pointer;
}

.project-item.active {
  /* Example: Add a shadow to the active item */
  transform: scale(1.05);
  z-index: 1;
  /* Example: Slightly scale up the active item */
  transition: all 0.3s ease-in-out;
  /* Smooth transition for the active state */
}

.selected-product {
  margin: 10px 0px !important;
  text-align: center;
}

.selected-product h2 {
  font-size: 21px !important;
}

.selected-product p {
  margin-bottom: 3px;
}



.newsroom-text h6 {
  position: absolute;
  bottom: 0;
  padding: 10px 40px;
  color: white;
  text-transform: capitalize;
  font-size: 20px;
  line-height: 30px;
}

.newsroom-text p {
  position: absolute;
  bottom: -12px;
  color: white;
  padding: 10px 15px;
  line-height: 20px;
  font-size: 13px;
  text-transform: capitalize;
}

/* Carousel */
.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel-status {
  display: none !important;
}

/* Infrastructure CSS Starts */
.infra-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.infra-plant {
  padding: 2rem 0rem;
  padding-bottom: 0px !important;
  /* position: relative; */
}

.infra-yarn-div1 {
  background: #1e4c96;
  padding: 1.2rem 3rem;
  width: auto;
  position: absolute;
  font-size: 25px;
  bottom: -40px;
  left: 90px;
  border-top-left-radius: 30px;
}

.infra-yarn-div1 h5 {
  color: white;
  margin-bottom: 0 !important;
  line-height: 30px;
  font-size: 22px
}

.infra-plant p {
  padding: 1rem 5.5rem;
  text-align: justify;
}

.infra-embroidery p {
  text-align: justify;
  /* font-size: 13px !important; */
}

.infra-embroidery .infra-bg {
  background: rgba(246, 246, 246, 1);
}

.infra-yarn-div2 {
  background: #1e4c96;
  padding: 1.2rem 3rem;
  border-top-left-radius: 30px;
  display: inline-block;

}

.infra-yarn-div2 h5 {
  color: white;
  margin-bottom: 0 !important;
  line-height: 30px;
  font-size: 22px;
}

.yarn-div-overlay {
  position: absolute;
  top: 135px;
}

.yarn-div-overlay p {
  color: white;
}

.text-justify {
  text-align: justify;
}







@media (max-width: 768px) {
  .business-heading h1 {
    font-size: 19px !important;
    margin-top: 20px;
    line-height: 28px;
  }

  .about-dis {
    padding: 2rem 0 !important;
  }

  .mgmt-details p {
    font-size: 12px !important;
    line-height: 18px !important;
  }

  .we-believe-left {
    text-align: center;
    margin-bottom: 20px;
  }

  .company-text {
    top: 25%;
  }

  .company-text h5,
  .company-text .company-dec {
    color: white !important;
  }

  .company-text h5 {
    font-size: 16px;
  }

  .company-dec {
    font-size: 12px;
    font-weight: normal !important;
  }

  .company-name {
    font-weight: 500 !important;
  }

  .phone-email {
    font-size: 16px;
  }

  .revenue-box {
    width: 210px;
    position: absolute;
    top: 20%;
    left: 10%;
    background: white;
  }

  .revenue-box h6,
  p {
    font-size: 13px;
    line-height: 23px;
  }

  .mobile-none {
    display: none;
  }

  .video-banner-content-center h1 {
    color: #fff;
    /* font-weight: 700; */
    font-size: 38px;
  }

  .video-banner-content-center h4 {
    color: rgb(255, 255, 255);
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 30px;
  }

  .newsroom-text h6 {
    position: absolute;
    bottom: 0;
    padding: 10px 40px;
    color: white;
    text-transform: capitalize;
    font-size: 19px;
    line-height: 25px;
  }

  .newsroom-text p {
    position: absolute;
    bottom: -12px;
    padding: 10px 20px;
    color: white;
    text-transform: capitalize;
    font-size: 13px;
    line-height: 20px;
  }

  .product-items {
    padding: 0px 10px !important;
  }

  .infra-yarn-div1 {
    background: #1e4c96;
    padding: 1rem 2rem;
    width: auto;
    position: absolute;
    font-size: 25px;
    bottom: -40px;
    left: 90px;
    border-top-left-radius: 30px;
  }

  .infra-yarn-div1 h5 {
    color: white;
    margin-bottom: 0 !important;
    line-height: 30px;
    font-size: 15px
  }

  .infra-yarn-div2 {
    background: #1e4c96;
    padding: 1rem 1.2rem;
    border-top-left-radius: 30px;
    display: inline-block;
  }

  .infra-yarn-div2 h5 {
    color: white;
    margin-bottom: 0 !important;
    line-height: 30px;
    font-size: 17px;
  }

  .yarn-div-overlay {
    position: absolute;
    top: 10%;
  }

  .yarn-div-overlay p {
    line-height: 19px;
    font-weight: 400 !important;
    padding: 0;
    font-size: 12px;
    color: white;
  }

  .footer-contact {
    justify-content: center;
  }

  .project-content h6 {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

}

@media (max-width:425px) {
  .revenue-box {
    width: 197px;
    position: absolute;
    top: 5%;
    left: 0%;
    background: white;
  }

  .product-items {
    padding: 0px 10px !important;
  }

  .footer-contact {
    justify-content: center;
  }

  .footer-social .list-wrap {
    display: flex;
    /* gap: 20px; */
    font-size: 22px;
  }

  .project-content h6 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .video-banner-content-center {
    margin-top: 20px;
  }

  .video-banner-content-center h1 {
    color: #fff;
    /* font-weight: 700; */
    font-size: 18px;
  }

  .video-banner-content-center h4 {
    color: rgb(255, 255, 255);
    margin-bottom: 25px;
    font-size: 10px;
    line-height: 15px;
  }


  .newsroom-text h6,
  .newsroom-text p {
    position: absolute;
    bottom: 0;
    padding: 10px 30px;
    color: white;
    text-transform: capitalize;
    font-size: 13px;
    line-height: 20px;
  }

  .infra-plant p {
    padding: 0;
    text-align: justify;
  }

  .yarn-div-overlay {
    position: static;
    margin-top: -60px;
    z-index: 1;
  }

  .yarn-div-overlay p {
    line-height: 19px;
    font-weight: 400 !important;
    padding: 0;
    font-size: 12px;
    color: black;
  }

  /* .mgmt-details p {
   font-size: 15px !important;
   line-height: 25px !important;
 } */
}

@media (max-width: 320px) {
  .wrap-border .footer-contact {
    margin-bottom: 10px;
  }


  .infra-yarn-div1 {
    background: #1e4c96;
    padding: 0.8rem 1rem;
    width: auto;
    position: absolute;
    font-size: 25px;
    bottom: -40px;
    left: 30px;
    border-top-left-radius: 30px;
  }

  .infra-yarn-div1 h5 {
    font-size: 12px;
    line-height: 20px;
  }

  .infra-yarn-div2 {
    background: #1e4c96;
    padding: 0.8rem 1rem;
    border-top-left-radius: 30px;
    display: inline-block;

  }

  .infra-yarn-div2 h5 {
    color: white;
    margin-bottom: 0 !important;
    line-height: 20px;
    font-size: 12px;
  }


  .contact-title .title {
    font-size: 30px;
    text-align: center;
  }

  .revenue-box {
    width: 150px;
    position: absolute;
    top: 10%;
    padding: 5px 5px !important;
    background: white;
  }

  .revenue-box h6,
  p {
    font-size: 10px;
  }
}

/* investor-section */
.investor-section {
  padding-top: 4rem;
  padding-bottom: 4rem;

  /* background-image: url(/public/assets/img/Business/investor-bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;



  .section-title {
    margin-bottom: 2rem;

    .title {
      font-size: 2rem;
      font-weight: bold;
      color: #333;
    }
  }

  .document-list {
    .document-item {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      .icon {
        width: 50px;
        height: auto;
      }

      .document-text {
        margin-left: 1rem;
        font-size: 1rem;
        color: #1A1A1A;
      }
    }
  }

  .investor-chart {
    text-align: center;
    position: relative;

    .img-fluid {
      max-width: 100%;
      height: auto;
    }
  }
}

/* .custom-container-2{
  max-width: 1370px;
} */
.investor-slider {
  background: rgba(246, 246, 246, 1);
}

.investor-slider-content {
  padding: 1rem 0;
  width: auto;
  border: solid 1px rgb(211, 208, 208);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  margin: 0px 15px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.investor-slider-content:hover {
  background: rgba(18, 83, 155, 1);
  color: white;
}

.investor-slider-content img {
  width: 80px;
  height: 80px;
}

.investor-slider-content p {
  margin-bottom: 0 !important;
  color: black;
  transition: color ease-in-out 0.3s;
  font-size: 13px;
}

.investor-slider-content:hover p {
  color: white;
}

.bg-active {
  background: rgba(18, 83, 155, 1);
  color: white;
}

.bg-active p {
  color: white;
}

.active-content {
  background: rgba(246, 246, 246, 1);
  padding: 1.5rem 2rem;
  border: 1px solid rgba(174, 172, 172, 1);
}

/* Investor sec ends */


@media (max-width: 991px) {
  .investor-section {
    .section-title {
      .title {
        font-size: 1.5rem;
      }
    }

    .document-list {
      /* .document-item {
                flex-direction: column;
                align-items: flex-start; */

      .document-text {
        margin-left: 0;
        margin-top: 0.5rem;
      }

      /* } */
    }
  }
}