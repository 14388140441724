.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.loader {
  width: 80px;
  height: 80px;
  animation: zoom 1s ease-in-out infinite;
}

@keyframes zoom {

  0%,
  50%,
  100% {
    transform: scale(1);
  }

  25%,
  75% {

    transform: scale(1.2);
  }
}